import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.array.find-index.js";
import "core-js/modules/es6.array.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Router from '@/mixins/Router';
export default {
  name: 'GameFilterLinks',
  mixins: [Router],
  props: {
    links: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      swiperOption: {
        freeMode: true,
        lazy: true,
        loadOnTransitionStart: true,
        slidesPerView: 'auto',
        spaceBetween: 0,
        slidesPerColumn: 1,
        watchSlidesVisibility: true
      },
      isTabClick: false
    };
  },
  computed: {
    linkIndex: function linkIndex() {
      var routePath = "/".concat(this.$route.name, "/").concat(this.$route.params.pathMatch);
      return this.links.findIndex(function (link) {
        return link.url === routePath;
      });
    },
    link: function link() {
      var routePath = "/".concat(this.$route.name, "/").concat(this.$route.params.pathMatch);
      return this.links.find(function (link) {
        return link.url === routePath;
      });
    },
    screenWidth: function screenWidth() {
      return this.$q.screen.width;
    }
  },
  watch: {
    $route: function $route() {
      if (!this.isTabClick) {
        this.myswiper.slideTo(this.linkIndex);
      }

      this.isTabClick = false;
    }
  },
  methods: {
    onClickTab: function onClickTab() {
      this.isTabClick = true;
    }
  },
  mounted: function mounted() {
    this.myswiper.slideTo(this.linkIndex);
  }
};